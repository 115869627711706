import { useMutation } from "@apollo/client"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Select, { ISelectOption } from "@cbs-sports/sports-shared-client/build/cjs/components/Select"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import Typography from "@material-ui/core/Typography"
import React, { useContext, useState } from "react"
import styled from "styled-components/macro"
import { AdminUpdateBracketStateMutation, AdminUpdateBracketStateMutationVariables } from "../../../__generated__/AdminUpdateBracketStateMutation"
import LoadingView from "../../components/LoadingView"
import PoolDataContext, { PoolDataContextType } from "../../Contexts/PoolDataContext"
import Layout from "../components/Layout"
import { ADMIN_UPDATE_BRACKET_STATE_MUTATION } from "../queries"

const stateTexts = {
  0: "Select",
  1: "Before Selection Sunday - No Teams",
  2: "Before Selection Sunday - First 8 Team Selected",
  3: "Picks are available, play in-games have not started yet",
  4: "Play-in games in progress, some are finalized, picks open",
  5: "Play-in games complete, 1st Round hasn't started, picks open",
  6: "BPM/BPC Picks are locked 1st Round Games in Progress, some are finalized, Standings now available",
  7: "All 1st Round Games completed, 2nd Round hasn't started",
  8: "2nd Round is Complete, Sweet 16 hasn't started",
  9: "Sweet 16 games complete, Elite 8 not started (useful with Sweet 16 Game)",
  10: "Elite 8 games complete, Final Four has not started yet",
  11: "Final Four Games Complete, Championship Game hasn't started",
  12: "Championship Game done, tournament complete",
}

const selectOptions = Object.keys(stateTexts)
  .sort((a, b) => Number(a) - Number(b))
  .map((x) => ({ label: stateTexts[x], value: Number(x) }))

const BracketStatesContent = styled.div`
  display: flex;
  flex-direction: row;

  & > div.options {
    flex: 1;
    max-width: 40rem;
    margin-right: 1rem;
  }
  & > div.actions {
  }
`

const BracketStates = () => {
  const [loading, setLoading] = useState(false)
  const [currentState, setCurrentState] = useState<number>(0)
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { allSegments, allGameInstances } = poolDataContext || emptyObject
  const sectionGameInstance = allGameInstances?.find((gi) => /cbs-ncaab-tournament/.test(gi.uid))
  const sectionSegment = allSegments.find((s) => s?.season?.gameInstance?.id === sectionGameInstance?.id)

  const [updateBracketStateMutation, updateBracketStateMutationResult] = useMutation<
    AdminUpdateBracketStateMutation,
    AdminUpdateBracketStateMutationVariables
  >(ADMIN_UPDATE_BRACKET_STATE_MUTATION)

  const handleSwitchState = () => {
    if (currentState !== 0 && sectionSegment) {
      setLoading(true)
      updateBracketStateMutation({
        variables: {
          segmentId: sectionSegment.id,
          state: currentState,
        },
      })
        .then(() => {
          if (updateBracketStateMutationResult.error) {
            console.log("ERROR: ", updateBracketStateMutationResult.error)
          }
        })
        .finally(() => setLoading(false))
    }
    console.log(currentState)
  }
  const handleSelectChange = (newItem: ISelectOption) => {
    if (typeof newItem.value === "number") {
      setCurrentState(newItem.value)
    } else {
      setCurrentState(0)
    }
  }
  return (
    <Layout title="Sim - Bracket States">
      <Typography variant="h4" component="h2">
        States
      </Typography>
      <br />
      {!loading && (
        <BracketStatesContent>
          <div className="options">
            <Select options={selectOptions} onSelect={handleSelectChange} />
          </div>
          <div className="actions">
            <Button onClick={handleSwitchState}>Switch State</Button>
          </div>
        </BracketStatesContent>
      )}
      {loading && <LoadingView />}
    </Layout>
  )
}
export default BracketStates
